import React from 'react';
import { Github, Linkedin, Mail } from 'lucide-react';
import { motion } from 'framer-motion';

export function SocialLinks() {
  return (
    <motion.div 
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: 1 }}
      className="fixed left-6 bottom-6 z-50 hidden md:flex flex-col gap-4"
    >
      <div className="flex flex-col gap-4 items-center">
        <motion.a
          whileHover={{ scale: 1.2, x: 5 }}
          whileTap={{ scale: 0.9 }}
          href="https://github.com/aditiawjy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-muted-foreground hover:text-primary transition-colors duration-300"
        >
          <Github size={20} />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.2, x: 5 }}
          whileTap={{ scale: 0.9 }}
          href="https://www.linkedin.com/in/aditwjy/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-muted-foreground hover:text-primary transition-colors duration-300"
        >
          <Linkedin size={20} />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.2, x: 5 }}
          whileTap={{ scale: 0.9 }}
          href="mailto:rva.ant@gmail.com"
          className="text-muted-foreground hover:text-primary transition-colors duration-300"
        >
          <Mail size={20} />
        </motion.a>
        <div className="w-px h-24 bg-muted-foreground/20"></div>
      </div>
    </motion.div>
  );
}
