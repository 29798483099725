import React from 'react';
import { motion } from 'framer-motion';

export function About() {
  return (
    <section id="about" className="relative py-24 overflow-hidden">
      {/* Right Side About Text - Hidden on mobile */}
      <div className="hidden md:flex absolute right-4 lg:right-8 top-1/2 -translate-y-1/2 flex-col items-center gap-4">
        <div className="w-px h-24 bg-primary/20"></div>
        <div className="rotate-90 text-sm tracking-widest text-primary/50 whitespace-nowrap uppercase font-semibold">
          About Me
        </div>
        <div className="w-px h-24 bg-primary/20"></div>
      </div>

      <div className="container mx-auto px-4 md:px-24 lg:px-32">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col md:flex-row items-center gap-12"
          >
            <div className="w-full md:w-1/3">
              <div className="relative w-48 h-48 sm:w-64 sm:h-64 mx-auto">
                <div className="absolute inset-0 bg-gradient-to-br from-primary/20 to-secondary/20 rounded-full animate-spin-slow" style={{ padding: '3px' }}>
                  <div className="bg-background w-full h-full rounded-full overflow-hidden">
                    <img
                      src="/images/profile.jpeg?height=256&width=256"
                      alt="Aditia"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-2/3 text-center md:text-left">
              <motion.h1
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="text-3xl sm:text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary mb-4"
              >
                ADITIA
              </motion.h1>
              <motion.h2
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="text-xl sm:text-2xl md:text-3xl font-semibold text-primary mb-6"
              >
                IT Web & Mobile Apps Developer
              </motion.h2>
              <motion.p
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="text-base sm:text-lg leading-relaxed text-muted-foreground max-w-2xl mx-auto md:mx-0"
              >
                I'm a professional in web and mobile application development. Currently, I work at Eikon
                Technology as a Cloud Engineer, supporting the company's business operations and
                strategies. With my experience, I am committed to providing innovative and efficient
                solutions to improve business performance and growth.
              </motion.p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}