import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, ExternalLink, Github, PlayCircle, ChevronLeft, ChevronRight, X } from 'lucide-react';

const projects = [
  {
    title: "Custom License Assignments",
    description: "Developed a custom license management system on GCP, enabling license monitoring, assignment, revocation, and upgrades.",
    period: "October 2024 - Present",
    tech: "GCP & Laravel",
    type: "Cloud",
    images: ["/images/projects/sat_1.jpg?height=300&width=400", "/images/projects/sat_2.jpg?height=300&width=400", "/images/projects/sat_3.jpg?height=300&width=400"]
  },
  {
    title: "Binamandiri",
    description: "Built a website for Binamandiri with a focus on user experience and user engagement.",
    period: "Jan 2024",
    tech: "React",
    type: "Website",
    images: ["/images/projects/biman_1.jpg?height=300&width=400", "/images/projects/biman_2.jpg?height=300&width=400", "/images/projects/biman_3.jpg?height=300&width=400"]
  },
  {
    title: "Mobile App BTI",
    description: "An Android application for internal goods transfer receipts in the warehouse, sent to the admin using registered local WiFi",
    period: "Nov 2023 - 2024",
    tech: "Flutter",
    type: "Mobile",
    images: ["/images/projects/bti_1.jpg?height=300&width=400", "/images/projects/bti_2.jpg?height=300&width=400", "/images/projects/bti_3.jpg?height=300&width=400"]
  },
  {
    title: "Mobile App Toyscity Member",
    description: "An Android application that allows members to view their points after purchases, earn points, redeem points, find nearby store locations, explore vendor brands and promotional products, and receive Firebase notifications.",
    period: "May 2023 - 2024",
    tech: "Flutter",
    type: "Mobile",
    link: "toyscity Play Store",
    url: "#",
    images: ["/images/projects/toyscity_1.jpg?height=300&width=400", "/images/projects/toyscity_2.jpg?height=300&width=400", "/images/projects/toyscity_3.jpg?height=300&width=400"]
  },
  {
    title: "Frontend & Backend Toyscity",
    description: "Build a website for users to view their member points and create an admin panel for managing information and data for the back office.",
    period: "Jan 2023 - 2024",
    tech: "Next.js & Node.js",
    type: "Web",
    link: "hipbiz.id/toyscityadmin/",
    url: "https://hipbiz.id/toyscityadmin/",
    images: ["/images/projects/toyscityadmin_1.jpg?height=300&width=400", "/images/projects/toyscityadmin_2.jpg?height=300&width=400", "/images/projects/toyscityadmin_3.jpg?height=300&width=400"]
  },
  {
    title: "Mobile App Intex Sales Counter",
    description: "An Android application designed to facilitate sales by enabling direct input of sales items, generating",
    period: "Nov 2022 - 2024",
    tech: "Flutter",
    type: "Mobile",
    link: "IIIP Sales Counter Play Store",
    url: "#",
    images: ["/images/projects/salesorder_1.jpg?height=300&width=400", "/images/projects/salesorder_2.jpg?height=300&width=400", "/images/projects/salesorder_3.jpg?height=300&width=400"]
  },
  {
    title: "RBO Sales Order",
    description: "An application created by the seller to confirm the sale of products or services to the buyer, including details such as ordered products, quantity, price, and applicable discounts.",
    period: "Jun 2021 - 2024",
    tech: "CodeIgniter",
    type: "Web",
    link: "rbo.hipbiz.id/",
    url: "https://rbo.hipbiz.id/",
    images: ["/images/projects/rbo_1.png?height=300&width=400", "/images/projects/rbo_2.png?height=300&width=400", "/images/projects/rbo_2.png?height=300&width=400"]
  },
  {
    title: "Intex Sales Counter",
    description: "An Android application designed to facilitate sales by enabling direct input of sales items, generating",
    period: "Jun 2021 - 2024",
    tech: "Laravel",
    type: "Web",
    link: "rbo.hipbiz.id/",
    url: "https://hipbiz.id/salescounter/",
    images: ["/images/projects/salescounter_1.png?height=300&width=400", "/images/projects/salescounter_2.png?height=300&width=400", "/images/projects/salescounter_2.png?height=300&width=400"]
  }
];

function Carousel({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const nextSlide = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <>
      <div className="relative w-full h-64 overflow-hidden rounded-lg">
        {images.map((image, index) => (
          <motion.img
            key={index}
            src={image}
            alt={`Project image ${index + 1}`}
            className="absolute top-0 left-0 w-full h-full object-cover cursor-pointer"
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentIndex ? 1 : 0 }}
            transition={{ duration: 0.5 }}
            onClick={() => openFullscreen(image)}
          />
        ))}
        <button
          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full"
          onClick={prevSlide}
        >
          <ChevronLeft size={24} />
        </button>
        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full"
          onClick={nextSlide}
        >
          <ChevronRight size={24} />
        </button>
      </div>

      <AnimatePresence>
        {fullscreenImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/90"
            onClick={closeFullscreen}
          >
            <button
              className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
              onClick={closeFullscreen}
            >
              <X size={32} />
            </button>
            <img
              src={fullscreenImage}
              alt="Fullscreen view"
              className="max-w-[90vw] max-h-[90vh] object-contain"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export function Projects() {
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 4;

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section id="projects" className="py-20 bg-gradient-to-b from-background to-muted">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold mb-12 text-center"
        >
          My Projects
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {currentProjects.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="bg-card text-card-foreground rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition-shadow">
                <Carousel images={project.images} />
                <div className="p-6">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-xl font-semibold">{project.title}</h3>
                    <span className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                      {project.type}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-muted-foreground mb-4">
                    <Calendar size={16} />
                    <span>{project.period}</span>
                  </div>
                  <p className="text-muted-foreground mb-4">
                    {project.description}
                  </p>
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium bg-secondary/10 text-secondary px-3 py-1 rounded-full">
                      {project.tech}
                    </span>
                    {project.url && (
                      <a
                        href={project.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-primary hover:text-primary/80 transition-colors"
                      >
                        {project.link.includes('Play Store') ? (
                          <>
                            <PlayCircle size={18} className="mr-1" />
                            Play Store
                          </>
                        ) : (
                          <>
                            <ExternalLink size={18} className="mr-1" />
                            Visit Project
                          </>
                        )}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="mt-12 flex justify-center">
          {Array.from({ length: Math.ceil(projects.length / projectsPerPage) }).map((_, index) => (
            <motion.button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`mx-1 px-4 py-2 rounded-full ${
                currentPage === index + 1 ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'
              }`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {index + 1}
            </motion.button>
          ))}
        </div>
      </div>
    </section>
  );
}