import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Circle } from 'lucide-react';

const workExperiences = [
  {
    company: "Eikon Technology",
    position: "Cloud Engineer",
    period: "Sep 2024 - Present",
    description: [
      "Learning About GCP (Google Cloud Platform)",
      "Worked on projects for clients."
    ]
  },
  {
    company: "PT Hero Intiputra",
    position: "IT Web Developer & Mobile Apps",
    period: "Nov 2013 - Sep 2024",
    description: [
      "Designed, developed, and maintained software applications, while managing business websites, and fixing bugs for both web and mobile applications.",
      "Created mobile applications to support business growth.",
      "Maintained domains and hosting to ensure operational continuity.",
      "Manage business websites, maintained domains and hosting",
      "Stay updated with emerging technologies and industry trends",
      "Integrated websites and mobile apps with APIs."
    ]
  },
  {
    company: "Altermyth",
    position: "Junior PHP Developer",
    period: "Jun 2011 - Mar 2013",
    description: [
      "Developed a download platform backend using PHP and MySQL.",
      "Tested websites, applications, and games for security and bugs.",
      "Created a project management website for effective monitoring."
    ]
  }
];

export function WorkHistory() {
  return (
    <section id="work-history" className="py-24">
      <div className="container mx-auto px-4 md:px-24 lg:px-32">
        <motion.h2 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-3xl font-bold mb-16 text-center"
        >
          Work Experience
        </motion.h2>

        <div className="relative max-w-6xl mx-auto">
          {/* Vertical Timeline Line - Hidden on mobile */}
          <div className="hidden md:block absolute left-[15px] md:left-1/2 top-0 h-full w-[2px] bg-gradient-to-b from-primary/50 via-primary/20 to-transparent" />

          {workExperiences.map((job, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className={`relative flex flex-col md:grid md:grid-cols-2 gap-4 md:gap-8 mb-12 ${
                index % 2 === 0 ? '' : 'md:text-right'
              }`}
            >
              {/* Timeline Node - Hidden on mobile */}
              <div className="hidden md:flex absolute left-0 md:left-1/2 -translate-x-[14px] md:-translate-x-[14px] mt-8">
                <div className="w-7 h-7 rounded-full border-2 border-primary bg-background flex items-center justify-center">
                  <Circle className="w-3 h-3 text-primary" />
                </div>
              </div>

              {/* Content */}
              <div className={`w-full ${
                index % 2 === 0 
                  ? 'md:pr-16' 
                  : 'md:pl-16 md:col-start-2'
              }`}>
                <div className="bg-card shadow-lg hover:shadow-xl transition-all duration-300 rounded-lg overflow-hidden">
                  {/* Mobile Timeline Indicator */}
                  <div className="md:hidden w-2 h-full bg-primary/20 absolute left-0 top-0" />
                  
                  {/* Header Section */}
                  <div className="bg-primary/5 p-4 md:p-6 border-b border-primary/10">
                    <div className="inline-flex items-center gap-1.5 text-sm text-muted-foreground mb-3 bg-background/50 backdrop-blur-sm px-3 py-1 rounded-full">
                      <Calendar size={14} />
                      <span>{job.period}</span>
                    </div>
                    <h3 className="text-lg md:text-xl font-semibold text-primary">{job.position}</h3>
                    <h4 className="text-base md:text-lg text-muted-foreground">{job.company}</h4>
                  </div>

                  {/* Description Section */}
                  <div className="p-4 md:p-6">
                    <ul className="space-y-2 md:space-y-3 text-sm md:text-base">
                      {job.description.map((item, i) => (
                        <li key={i} className="text-muted-foreground flex items-start gap-3 group-hover:text-foreground/90">
                          <span className="w-1.5 h-1.5 rounded-full bg-primary/70 mt-2 flex-shrink-0"></span>
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}