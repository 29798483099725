import React from 'react';
import { motion } from 'framer-motion';
import { Code, Smartphone, Cloud } from 'lucide-react';

export function Hero() {
  return (
    <section className="relative min-h-screen flex items-center justify-center overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0" />
      
      <div className="container mx-auto px-4 sm:px-6 md:px-10 py-24">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-8 md:gap-12">
          {/* Left side - Introduction */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center lg:text-left w-full lg:w-1/2"
          >
            <h1 className="text-4xl sm:text-6xl lg:text-7xl xl:text-8xl font-bold mb-4 sm:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary via-purple-500 to-secondary">
              Aditia
            </h1>
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-6 sm:mb-8 text-foreground">
              IT Web & Mobile Apps Developer
            </h2>
            <p className="text-lg sm:text-xl lg:text-2xl mb-8 sm:mb-12 text-muted-foreground">
              Crafting innovative digital solutions for web and mobile platforms
            </p>

            <div className="flex flex-wrap justify-center lg:justify-start gap-3 sm:gap-4">
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="flex items-center bg-primary/10 backdrop-blur-sm text-primary px-4 sm:px-6 py-2 sm:py-3 rounded-full hover:bg-primary/20 transition-all duration-300 text-sm sm:text-base"
              >
                <Code className="mr-2 w-4 h-4 sm:w-5 sm:h-5" />
                <span>Web Development</span>
              </motion.div>
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="flex items-center bg-secondary/10 backdrop-blur-sm text-secondary px-4 sm:px-6 py-2 sm:py-3 rounded-full hover:bg-secondary/20 transition-all duration-300 text-sm sm:text-base"
              >
                <Smartphone className="mr-2 w-4 h-4 sm:w-5 sm:h-5" />
                <span>Mobile Apps</span>
              </motion.div>
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="flex items-center bg-muted backdrop-blur-sm text-muted-foreground px-4 sm:px-6 py-2 sm:py-3 rounded-full hover:bg-muted/70 transition-all duration-300 text-sm sm:text-base"
              >
                <Cloud className="mr-2 w-4 h-4 sm:w-5 sm:h-5" />
                <span>Cloud Engineering</span>
              </motion.div>
            </div>
          </motion.div>

          {/* Right side - Code */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className="w-full lg:w-1/2"
          >
            <div className="bg-[#0d1117] rounded-lg border border-gray-800 shadow-xl max-w-xl mx-auto">
              <div className="border-b border-gray-800 p-3 sm:p-4 flex gap-2">
                <div className="w-2.5 h-2.5 sm:w-3 sm:h-3 rounded-full bg-red-500" />
                <div className="w-2.5 h-2.5 sm:w-3 sm:h-3 rounded-full bg-yellow-500" />
                <div className="w-2.5 h-2.5 sm:w-3 sm:h-3 rounded-full bg-green-500" />
              </div>
              <pre className="p-3 sm:p-4 text-xs sm:text-sm font-mono overflow-x-auto">
                <code className="text-gray-300">
                  <div className="whitespace-pre">
                    <span className="text-pink-500">const</span>{" "}
                    <span className="text-blue-400">coder</span> = {"{"}
                    <br />
                    {"  "}name: <span className="text-yellow-300">&apos;Aditia&apos;</span>,
                    <br />
                    {"  "}skills:[
                    <span className="text-yellow-300">
                      &apos;PHP&apos;, &apos;Laravel&apos;, &apos;Codeigniter&apos;, &apos;NodeJS&apos;,
                      <br />
                      {"  "}
                      {"  "}&apos;React&apos;, &apos;MySQL&apos;, &apos;NextJS&apos;, &apos;Tailwind&apos;,
                      &apos;Javascript&apos;
                    </span>
                    ],
                    <br />
                    {"  "}hardWorker: <span className="text-orange-500">true</span>,
                    <br />
                    {"  "}quickLearner: <span className="text-orange-500">true</span>,
                    <br />
                    {"  "}problemSolver: <span className="text-orange-500">true</span>,
                    <br />
                    {"  "}
                    <span className="text-purple-400">hireable</span>:{" "}
                    <span className="text-pink-500">function</span>() {"{"}
                    <br />
                    {"    "}
                    <span className="text-pink-500">return</span> (
                    <br />
                    {"      "}
                    <span className="text-blue-400">this</span>.hardWorker &&
                    <br />
                    {"      "}
                    <span className="text-blue-400">this</span>.problemSolver &&
                    <br />
                    {"      "}
                    <span className="text-blue-400">this</span>.skills.length {">"}= 5
                    <br />
                    {"    "});
                    <br />
                    {"  "}
                    {"}"}
                    <br />
                    {"}"};
                  </div>
                </code>
              </pre>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}